import './init';

import Vue from 'vue';
import './plugins/vuetify';

import Vuetify from 'vuetify';
Vue.use(Vuetify);

import CubeLogger from '@/Logger';
Vue.use(CubeLogger);

import Formatters from '@/Formatters';
Vue.use(Formatters);

import { createPinia, PiniaVuePlugin } from 'pinia';
Vue.use(PiniaVuePlugin);
export const pinia = createPinia();

// Language control and switching
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
const i18n: VueI18n = new VueI18n();

import VueApexCharts from 'vue-apexcharts';

Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

import { useI18nStore } from '@/I18nStore';

// Router controls the app
import VueRouter from 'vue-router';
import router from '@/router/router';
Vue.use(VueRouter);

// VueJS stuff
import '@/directives';
Vue.config.productionTip = false;

import '@/assets/global.css';

import EnvironmentHandler from './EnvironmentHandler';
// Late import to avoid timing issues
import App from '@/App.vue';

// We add a "?date=1619675159013" (example) to "bust" caching
const environmentUrl: string = window.location.origin + '/environment.json?date=' + new Date().getTime().toString();
let app: Vue;

window
  .fetch(environmentUrl)
  .then(response => {
    return response.json();
  })
  .then(json => {
    EnvironmentHandler.init(json);

    app = new Vue({
      el: '#app',
      router,
      i18n,
      pinia,
      render: h => h(App)
    });

    const i18nStore = useI18nStore();
    i18nStore.init(i18n);
  });
