import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

// Dashboard/Navigation Bar
import IconNavigationMenu from '@/components/general/icons/navigation/IconMenu.vue';
import IconNavigationDashboard from '@/components/general/icons/navigation/IconDashboard.vue';
import IconNavigationAlarm from '@/components/general/icons/navigation/IconAlarm.vue';
import IconNavigationMessage from '@/components/general/icons/navigation/IconMessage.vue';
import IconNavigationReport from '@/components/general/icons/navigation/IconReport.vue';
import IconNavigationAppliance from '@/components/general/icons/navigation/IconAppliance.vue';
import IconNavigationEditor from '@/components/general/icons/navigation/IconEditor.vue';
import IconNavigationSettings from '@/components/general/icons/navigation/IconSettings.vue';
import IconNavigationHelp from '@/components/general/icons/navigation/IconHelp.vue';
import IconNavigationUser from '@/components/general/icons/navigation/IconUser.vue';

import IconSmartCoolingHub from '@/components/general/icons/IconSmartCoolingHub.vue';
import IconSmartCoolingHubWhite from '@/components/general/icons/IconSmartCoolingHubWhite.vue';
import IconAdd from '@/components/general/icons/IconAdd.vue';
import IconMove from '@/components/general/icons/IconMove.vue';

import IconAppliance from '@/components/general/icons/IconAppliance.vue';
import IconApplianceWhite from '@/components/general/icons/IconApplianceWhite.vue';
import IconApplianceBig from '@/components/general/icons/IconApplianceBig.vue';
import IconApplianceDisconnected from '@/components/general/icons/IconApplianceDisconnected.vue';

import IconInternalActionLink from '@/components/general/icons/configurable/IconInternalActionLink.vue';
import IconActionLink from '@/components/general/icons/general/IconActionLink.vue';
import IconReload from '@/components/general/icons/general/IconReload.vue';

import IconComment from '@/components/general/icons/IconComment.vue';

import IconStatusOff from '@/components/general/icons/general/IconStatusOff.vue';
import IconStatusOn from '@/components/general/icons/general/IconStatusOn.vue';

import IconNetworkHigh from '@/components/general/icons/network/IconNetworkHigh.vue';
import IconNetworkMed from '@/components/general/icons/network/IconNetworkMed.vue';
import IconNetworkLow from '@/components/general/icons/network/IconNetworkLow.vue';

import IconUser from '@/components/general/icons/IconUser.vue';
import IconUserBig from '@/components/general/icons/IconUserBig.vue';

import IconDefrost from '@/components/general/icons/IconDefrost.vue';

import IconLicense from '@/components/general/icons/IconLicense.vue';

import IconSettingsBig from '@/components/general/icons/IconSettingsBig.vue';

import IconLink from '@/components/general/icons/IconLink.vue';

import IconPrint from '@/components/general/icons/IconPrint.vue';
import IconDownload from '@/components/general/icons/IconDownload.vue';
import IconClose from '@/components/general/icons/IconClose.vue';

import IconConnectivityOk from '@/components/general/icons/IconConnectivityOk.vue';
import IconConnectivityError from '@/components/general/icons/IconConnectivityError.vue';

import IconBarChart from '@/components/general/icons/IconBarChart.vue';
import IconPieChart from '@/components/general/icons/IconPieChart.vue';

import IconService from '@/components/general/icons/IconService.vue';
import IconCompany from '@/components/general/icons/IconCompany.vue';

// Message center icons
import IconDelete from '@/components/general/icons/IconDelete.vue';
import IconDeleteDisabled from '@/components/general/icons/IconDeleteDisabled.vue';
import IconPlus from '@/components/general/icons/IconPlus.vue';
import IconPlusDisabled from '@/components/general/icons/IconPlusDisabled.vue';
import IconPublish from '@/components/general/icons/IconPublish.vue';

// Data missing display
import IconApplianceMissing from '@/components/general/icons/datamissing/IconApplianceMissing.vue';
import IconContentMissing from '@/components/general/icons/datamissing/IconContentMissing.vue';
import IconHubMissing from '@/components/general/icons/datamissing/IconHubMissing.vue';
import IconStatusMissing from '@/components/general/icons/datamissing/IconStatusMissing.vue';
import IconNoContentSad from '@/components/general/icons/datamissing/IconNoContentSad.vue';

Vue.use(Vuetify, {
  options: {
    customProperties: true
  },
  theme: {
    primary: '#2E8DE5',
    secondary: '#003259',
    background_color: '#f1f1f1',
    error: '#fe655c',
    bus_green: '#43A017',
    bus_red: '#E52828',
    bus_yellow: '#FFD000',
    bus_white: '#ffffff',
    bus_black: '#000000',
    bus_light_grey: '#888E94',
    bus_grey: '#737373',
    neutral: '#F0F3F6',
    neutral20: '#E5E8ED',
    neutral40: '#BDC4CA',
    bus_clay_light: '#F89939',
    bus_clay: '#EB6F24',
    bus_clay_dark: '#B0542E',
    bus_terra_light: '#D1B795',
    bus_terra: '#AA8D6D',
    bus_terra_dark: '#846554',
    bus_ruby_light: '#F56E7C',
    bus_ruby: '#E34858',
    bus_ruby_dark: '#AD0B37',
    bus_leaf_light: '#5DBF90',
    bus_leaf: '#2EA675',
    bus_leaf_dark: '#33825E',
    bus_slate_light: '#8BB2C2',
    bus_slate: '#5789A4',
    bus_slate_dark: '#3B6579'
  },
  icons: {
    // Navigation Bar
    bus_navigation_menu: {
      component: IconNavigationMenu
    },
    bus_navigation_dashboard: {
      component: IconNavigationDashboard
    },
    bus_navigation_alarm: {
      component: IconNavigationAlarm
    },
    bus_navigation_message: {
      component: IconNavigationMessage
    },
    bus_navigation_report: {
      component: IconNavigationReport
    },
    bus_navigation_appliance: {
      component: IconNavigationAppliance
    },
    bus_navigation_editor: {
      component: IconNavigationEditor
    },
    bus_navigation_settings: {
      component: IconNavigationSettings
    },
    bus_navigation_help: {
      component: IconNavigationHelp
    },
    bus_navigation_user: {
      component: IconNavigationUser
    },

    // Standard
    bus_add: {
      component: IconAdd
    },
    bus_move: {
      component: IconMove
    },

    // Connectivity page icons
    bus_connectivity_ok: {
      component: IconConnectivityOk
    },
    bus_connectivity_error: {
      component: IconConnectivityError
    },

    // Sub folder: general
    bus_general_gateway: {
      component: IconSmartCoolingHub
    },
    bus_general_gateway_white: {
      component: IconSmartCoolingHubWhite
    },
    bus_general_internal_link: {
      component: IconInternalActionLink
    },
    bus_general_link: {
      component: IconActionLink
    },
    bus_general_reload: {
      component: IconReload
    },

    bus_general_status_on: {
      component: IconStatusOn
    },
    bus_general_status_off: {
      component: IconStatusOff
    },

    bus_comment: {
      component: IconComment
    },

    bus_network_high: {
      component: IconNetworkHigh
    },
    bus_network_med: {
      component: IconNetworkMed
    },
    bus_network_low: {
      component: IconNetworkLow
    },

    bus_appliance: {
      component: IconAppliance
    },
    bus_appliance_disconnected: {
      component: IconApplianceDisconnected
    },
    bus_appliance_white: {
      component: IconApplianceWhite
    },
    bus_appliance_big: {
      component: IconApplianceBig
    },
    bus_user: {
      component: IconUser
    },
    bus_user_big: {
      component: IconUserBig
    },
    bus_defrost: {
      component: IconDefrost
    },
    bus_license: {
      component: IconLicense
    },
    bus_settings_big: {
      component: IconSettingsBig
    },
    bus_service: {
      component: IconService
    },
    bus_company: {
      component: IconCompany
    },
    bus_link: {
      component: IconLink
    },
    bus_print: {
      component: IconPrint
    },
    bus_download: {
      component: IconDownload
    },
    bus_close: {
      component: IconClose
    },

    bus_chart_bar: {
      component: IconBarChart
    },
    bus_chart_pie: {
      component: IconPieChart
    },

    // Message editor icons
    bus_delete: {
      component: IconDelete
    },
    bus_delete_disabled: {
      component: IconDeleteDisabled
    },
    bus_plus: {
      component: IconPlus
    },
    bus_plus_disabled: {
      component: IconPlusDisabled
    },
    bus_publish: {
      component: IconPublish
    },

    // Data missing display
    bus_datamissing_appliance: {
      component: IconApplianceMissing
    },
    bus_datamissing_content: {
      component: IconContentMissing
    },
    bus_datamissing_hub: {
      component: IconHubMissing
    },
    bus_datamissing_status: {
      component: IconStatusMissing
    },
    bus_datamissing_no_content: {
      component: IconNoContentSad
    }
  }
});
